import { render, staticRenderFns } from "./SearchBar.vue?vue&type=template&id=0556ecf1&scoped=true&"
import script from "./SearchBar.vue?vue&type=script&lang=js&"
export * from "./SearchBar.vue?vue&type=script&lang=js&"
import style0 from "./SearchBar.vue?vue&type=style&index=0&id=0556ecf1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0556ecf1",
  null
  
)

export default component.exports