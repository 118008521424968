import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    name: 'home',
    path: '/',
    component: () =>
      import(/* webpackChunkName:"home" */ '../views/home/home.vue'),
    meta: {
      title: '确权链',
      type: 'home',
      zhName: '首页',
    },
  },
  {
    name: 'block',
    path: '/block',
    component: () =>
      import(/* webpackChunkName:"block" */ '@/views/block/block.vue'),
    meta: {
      title: '确权链',
      type: 'block',
      zhName: '区块',
    },
  },
  {
    name: 'block_info',
    path: '/block/:id',
    component: () =>
      import(
        /* webpackChunkName:"block-info" */ '../views/block/blockInfo.vue'
      ),
    meta: {
      title: '确权链',
      type: 'block',
      zhName: '区块详情',
    },
  },
  {
    name: 'assets',
    path: '/assets',
    component: () =>
      import(/* webpackChunkName:"assets" */ '../views/assets/assets.vue'),
    meta: {
      title: '确权链',
      type: 'assets',
      zhName: '通证',
    },
  },
  {
    name: 'assets_info',
    path: '/assets/:id',
    component: () =>
      import(/* webpackChunkName:"assets" */ '../views/assets/assetsInfo.vue'),
    meta: {
      title: '确权链',
      type: 'assets',
      zhName: '通证详情',
    },
  },
  {
    name: 'transaction',
    path: '/transaction',
    component: () =>
      import(
        /* webpackChunkName:"transaction" */ '../views/transaction/transaction.vue'
      ),
    meta: {
      title: '确权链',
      type: 'transaction',
      zhName: '交易',
    },
  },
  {
    name: 'transaction_info',
    path: '/transaction/:id',
    component: () =>
      import(
        /* webpackChunkName:"transaction" */ '../views/transaction/transactionInfo.vue'
      ),
    meta: {
      title: '确权链',
      type: 'transaction',
      zhName: '交易详情',
    },
  },
  {
    name: 'contract',
    path: '/contract',
    component: () =>
      import(
        /* webpackChunkName:"contract" */ '../views/contract/contract.vue'
      ),
    meta: {
      title: '确权链',
      type: 'contract',
      zhName: '合约',
    },
  },
  {
    name: 'contract_info',
    path: '/contract/:id',
    component: () =>
      import(
        /* webpackChunkName:"contract" */ '../views/contract/contractInfo.vue'
      ),
    meta: {
      title: '确权链',
      type: 'contract',
      zhName: '合约详情',
    },
  },
  // {
  //   name: 'data',
  //   path: '/data',
  //   component: () =>
  //     import(/* webpackChunkName:"data" */ '../views/data/data.vue'),
  //   meta: {
  //     title: '确权链',
  //     type: 'data',
  //     zhName: '数据',
  //   },
  // },
  {
    name: 'data_info',
    path: '/data/:type',
    component: () =>
      import(/* webpackChunkName:"data" */ '../views/data/dataInfo.vue'),
    meta: {
      title: '确权链',
      type: 'data',
      zhName: '数据详情',
    },
  },
  {
    name: 'search',
    path: '/search',
    component: () =>
      import(/* webpackChunkName:"search" */ '../views/search/search.vue'),
    meta: {
      title: '确权链',
      type: 'search',
      zhName: '查询',
    },
  },
  {
    name: 'account',
    path: '/account/:account',
    component: () =>
      import(/* webpackChunkName:"account" */ '@/views/account/account.vue'),
    meta: {
      title: '账户详情',
      type: 'account',
      zhName: '账户详情',
    },
  },
  {
    name: 'publicKey',
    path: '/publicKey/:publicKey',
    component: () =>
      import(
        /* webpackChunkName:"publicKey" */ '@/views/publicKey/publicKey.vue'
      ),
    meta: {
      title: '公钥详情',
      type: 'account',
      zhName: '公钥详情',
    },
  },
  // {
  //   name: 'token',
  //   path: '/token',
  //   component: () =>
  //     import(/* webpackChunkName:"token" */ '@/views/token/token.vue'),
  //   meta: {
  //     title: '代币',
  //     type: 'token',
  //     zhName: '代币',
  //   },
  // },
  {
    name: 'token',
    path: '/token/:token',
    component: () =>
      import(/* webpackChunkName:"tokenInfo" */ '@/views/token/token.vue'),
    meta: {
      title: '通证',
      type: 'token',
      zhName: '通证',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
