<template>
  <div class='footer'>
    <div class="page-container">
      <div class="content">
        <div class="info">
          <div class="logo">
            <img :src="require('@/assets/images/brand_white.png')">
          </div>
          <div class="contact">
            悦智互动资讯热线：<br />
            020-34809560
          </div>
        </div>
        <div class="menu">
          <div class="menu-column" v-for="col in bottomMenu" :key="col.title">
            <div class="main-title">{{ col.title }}</div>
            <div class="children" @click="navTo(children.path)" v-for="children in col.children" :key="children.title">
              {{ children.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="licence">
        粤ICP备13052947号 Copyright © 2007-2021
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bottomMenu: [
        {
          title: "首页",
          children: [
            {
              title: "区块",
              path: "/block"
            },
            {
              title: "交易",
              path: "/transaction"
            }
          ]
        },
        {
          title: "区块",
          children: []
        },
        {
          title: "通证",
          children: []
        },
        {
          title: "数据",
          children: []
        },
        {
          title: "关于",
          children: [
            {
              title: "关于我们",
              path: ""
            },
            {
              title: "加入我们",
              path: ""
            }
          ]
        },
      ]
    }
  },
  methods: {
    navTo(path) {
      if (!path) return
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  margin-top: 20px;
  padding: 20px;
  background-image: url('@/assets/images/bg_footer.png');
  background-repeat: no-repeat;
}

.content {
  border-bottom: 2px solid #ddd;
  padding: 20px 0;
  height: 140px;
  display: flex;

  .info {
    border-right: 2px solid #ddd;
    width: 250px;
    height: 100%;

    .logo {
      height: 50px;
      margin-bottom: 40px;
    }

    .contact {
      color: #ddd;
    }
  }
}

.menu {
  height: 100%;
  width: 100%;
  display: flex;
  padding-left: 60px;
  color: #ddd;

  .menu-column {
    display: flex;
    flex-direction: column;
    width: 140px;

    .main-title {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .children {
      padding: 10px 0;
      cursor: pointer;
      font-size: 14px;
    }
  }
}

.licence {
  text-align: center;
  padding-top: 20px;
  color: #ddd;
}
</style>
