<template>
  <div class='search-bar'>
    <div class="container">
      <!-- <div class="select">
        <el-select v-model="selectVal" class="custom-select" placeholder="选择搜索类型">
          <el-option v-for="option in selectOptions" :key="option.key" :value="option.value"
            :label="option.label"></el-option>
        </el-select>
      </div> -->
      <div class="input-container">
        <input type="text" v-model="keyword" @input="inputData" class="input-body" :placeholder="placeHolder"
          @keyup.enter="clickSearchHandle">
        <div class="button-container">
          <el-button class="custom-button" icon="el-icon-search" @click="clickSearchHandle"></el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeHolder: {
      type: String,
      default: () => "搜索 / 区块号# / 账户 / 公钥 / TX ID"
    },
    selectOptions: {
      type: String,
      default: () => { }
    }
  },
  data() {
    return {
      selectVal: '',
      keyword: '',
    }
  },
  watch: {
    $route: function (newVal, oldVal) {
      this.getSearchKeyFn()
    }
  },
  mounted() {
    window.onload = () => {
      this.getSearchKeyFn()
    }
  },
  methods: {
    inputData() {
      this.$emit('input', this.keyword)
    },
    clickSearchHandle() {
      if (!this.keyword) {
        this.$message.error('搜索词为空');
        return
      }
      this.keyword = this.keyword.trim()
      const keyword = this.keyword
      if (keyword.length == 53) {
        this.$router.push(`/publicKey/${keyword}/`).catch(err => { })
        return
      }
      if (keyword.length <= 12 && this.isAccountFn(keyword)) {
        this.$router.push(`/account/${keyword}/`).catch(err => { })
        return
      }
      if (this.isBlockNumFn(keyword)) {
        // 搜索块
        this.$router.push(`/block/${keyword}/`).catch(err => { })
        return
      }

      if (this.isTrx(keyword)) {
        encodeURIComponent()
        this.$router.push(`/transaction/${keyword}/`).catch(err => { })
        return
      }
      this.$message.error('搜索词不合法');
    },
    isAccountFn(str) {
      return /^([a-zA-Z\d])+(\.)?[a-zA-Z\d]+$/
        .test(str)
    },
    isTrx(str) {
      return /[0-9a-zA-Z]{64}/
        .test(str)
    },
    isBlockNumFn(str) {
      return /^[0-9]+#$/.test(str)
    },
    getSearchKeyFn() {
      if (this.$route.name != "token" && Object.keys(this.$route.params).length != 0) {
        for (let key in this.$route.params) {
          this.keyword = this.$route.params[key]
        }
      } else {
        this.keyword = ''
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.search-bar {
  width: 100%;
  display: flex;
  position: relative;
}

.container {
  display: flex;
  width: 100%;
  background-color: #fff;
  padding: 5px;
  border-radius: 8px;
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;

  .input-container {
    display: flex;
    flex-grow: 1;

    .input-body {
      flex-grow: 1;
      outline: none;
      border: none;
      padding: 0 60px 0 10px;
      padding-right: 60px;
    }

    .button-container {
      position: absolute;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);

      .custom-button {
        background-color: #00c78d;
        color: white;
        text-align: center;
        height: 50px;
      }
    }
  }
}

.custom-select ::v-deep .el-input .el-input__inner {
  border: none;
}

.custom-select ::v-deep .el-input {
  font-size: 16px;
}

.input-body::placeholder {
  font-size: 16px;
  color: #aaa;
}

.input-body {
  font-size: 16px;
}
</style>
