import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
import store from './store'

import './plugins/element.js'
import './assets/styles/index.scss'

import VueNativeSock from 'vue-native-websocket'

Vue.config.productionTip = false
Vue.use(
  VueNativeSock,
  'wss://infura.svconcloud.com/browser/socket.io/block?transport=websocket',
  {
    reconnection: true, // 自动重新连接 (false)
    reconnectionAttempts: Infinity, // 重新连接尝试次数 (Infinity),
    reconnectionDelay: 100, // 重新连接时间间隔
  }
)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
