<template>
  <div class='breadcrumb' v-show="show">
    <div class="container">
      <div class="bread-item" @click="navTo(item)" v-for="item in navList" :key="item.name">
        {{ item.meta.zhName }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [],
      show: true,
      unShowPage: ['search', 'home']
    }
  },
  watch: {
    $route(to) {
      this.navList = []
      if (this.unShowPage.includes(to.name)) {
        this.show = false
      } else {
        this.show = true
      }
      if (to.meta.type === 'home') {
        if (this.navList.length === 0) {
          this.navList.push(to)
        } else {
          this.navList.splice(1)
        }
      } else {
        if (this.navList.length === 0) {
          this.navList.push(this.$router.options.routes[0])
        }
        let last = this.navList[this.navList.length - 1]
        if (last.meta.type === to.meta.type) {
          let index = this.navList.findIndex(e => {
            return e.path == to.path
          })
          if (index == -1) {
            this.navList.push(to)
          } else {
            this.navList.splice(index + 1)
          }
        } else {
          this.navList.splice(1)
          this.navList.push(to)
        }
      }
    }
  },
  methods: {
    navTo(to) {
      this.$router.push(to)
    }
  }
}
</script>

<style lang='scss' scoped>
.breadcrumb {
  padding: 20px 0;
}

.container {
  width: 1200px;
  margin: 0 auto;
  display: flex;
}

.bread-item {
  color: gray;
  cursor: pointer;

  &::after {
    font-family: element-icons;
    content: "\e6e0";
    display: inline-block;
    padding: 0 15px;
  }

  &:nth-last-child(1) {
    color: black;
    cursor: default;

    &::after {
      display: none;
    }

  }
}
</style>
