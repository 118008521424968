<template>
  <div class='banner' :style="{ backgroundImage: `url(${backgroundImage})`, height: height }" v-show="showBanner">
    <div class="container">
      <div class="title" v-show="showTitle">联盟链</div>
      <div class="search-container">
        <SearchBar />
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from '../SearchBar/SearchBar.vue'
export default {
  components: {
    SearchBar
  },
  data() {
    return {
      showTitle: true,
      height: '200px',
      bgImage: require('@/assets/images/bg_banner.png'),
      backgroundImage: "",

      unShowPage: ['search'],
      showBanner: true,
    }
  },
  watch: {
    $route(to) {
      if (this.unShowPage.includes(to.name)) {
        this.showBanner = false
      } else {
        this.showBanner = true
      }
      if (to.meta.type === "home") {
        this.showTitle = true
        this.backgroundImage = this.bgImage
        this.height = '200px'
      } else {
        this.showTitle = false
        this.backgroundImage = ''
        this.height = 'auto'
      }
    }
  },
  methods: {

  }
}
</script>

<style lang='scss' scoped>
.banner {
  height: auto;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: linear-gradient(#00dd84, #44474f);
}

.container {
  width: 1200px;
  margin: 20px auto;

  .title {
    padding: 20px 0;
    font-size: 26px;
    font-weight: bold;
    color: white;
  }

  .search-container {
    width: 750px;
  }
}
</style>
