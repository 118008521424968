<template>
  <header class="header">
    <div class="container">
      <div class="left">
        <div class="logo">
          <img class="logo-image" :src="logoImage" alt="logo" @click="handleClick({ path: '/', })">
        </div>
      </div>
      <div class="right">
        <div class="menu">
          <div class="menu-item" :class="{ active: currentTab === menu.type }" v-for="(menu, index) in menuMap"
            :key='index' @click="handleClick(menu)">{{ menu.name }}</div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      logoImage: require('@/assets/images/brand_black.png'),
      menuMap: [
        {
          name: '首页',
          type: "home",
          path: "/",
        },
        {
          name: '区块',
          path: "/block",
          type: "block",
        },
        {
          name: '通证',
          path: "/assets",
          type: "assets"
        },
        {
          name: '交易',
          path: "/transaction",
          type: "transaction"
        },
        // {
        //   name: '代币',
        //   path: "/token",
        //   type: "token"
        // },
        // {
        //   name: '合约',
        //   path: "/contract",
        //   type: "contract"
        // },
        // {
        //   name: '数据',
        //   path: "/data",
        //   type: "data"
        // },
        // {
        //   name: '查询',
        //   path: "/search",
        //   type: "search"
        // },
      ],
      currentTab: "",
    }
  },
  watch: {
    $route(to, from) {
      this.currentTab = to.meta.type
    }
  },
  methods: {
    handleClick(menu) {
      if (this.$route.path == menu.path) return
      this.$router.push(menu.path)
    }
  }
}
</script>

<style lang='scss' scoped>
.header {
  width: 100vw;
  height: 70px;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 999;
}

.container {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;

  .left,
  .right {
    height: 50px;
  }

  .left {
    display: flex;
    align-items: center;

    .logo {
      img {
        cursor: pointer;
      }
    }
  }
}

.right {
  width: 100%;

  .menu {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .menu-item {
      padding: 0 20px;
      font-size: 18px;
      cursor: pointer;
      user-select: none;

      &.active {
        font-weight: bold;
        color: #00c78d;
      }
    }
  }
}
</style>
