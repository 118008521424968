<template>
  <div id="app">
    <Header />
    <div class="body-container">
      <Banner />
      <Breadcrumb />
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import Banner from '@/components/Banner/Banner.vue'
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue'
import Footer from '@/components/Footer/Footer.vue'
export default {
  components: {
    Header,
    Banner,
    Footer,
    Breadcrumb
  },
  data() {
    return {

    }
  }
}
</script>

<style scoped>
.container {
  width: 1200px;
  margin: 0 auto;
}

#app {
  display: flex;
  flex-direction: column;
}

.body-container {
  flex: 1;
}
</style>
